import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import SubscribeService from '../../../services/subscribe'

const TopicNewsletter = ({
    title,
    items,
    checkAllTopicValue,
    requireTopicValue,
    onChange,
    onCheckAll,
    isRequireTopicChecked,
    pageName,
    invalidTopic,
    isCheckAll,
}) => {
    const requiredTopicName = invalidTopic.indexOf(title) > -1
    const renderErrorMessages = () => {
        if (!requiredTopicName) {
            return null
        }

        if (title.toLowerCase().trim() === 'news') {
            return (
                <span className={'text-sm text-redsport-60 leading-6 text-right flex-grow justify-end'}>
                    <FormattedMessage id={'newsletterContact.newsRequired'} />
                </span>
            )
        }

        return (
            <span className={'text-sm text-redsport-60 leading-6 text-right flex-grow justify-end'}>
                <FormattedMessage id={'newsletterContact.sportRequired'} />
            </span>
        )
    }

    return (
        <div className={'mt-2'}>
            <p className={'flex mb-4 justify-between'}>
                <span className={'font-bold'}>
                    {title}
                    *
                </span>
                {title === requireTopicValue && (
                    <div className={'font-bold font-sport2000 text-sm leading-6 text-blacksport-50'}>
                        <FormattedMessage id={'validation.fieldRequired'} />
                    </div>
                )}
                {!isRequireTopicChecked && requiredTopicName && (
                    renderErrorMessages()
                )}
            </p>

            {title === checkAllTopicValue && (
                <div className={'mb-3'}>
                    <div className={'checkbox-radio--wrapper w-full'}>
                        <input
                            type={'checkbox'}
                            id={`${pageName}-checkAll`}
                            name={'checkAll'}
                            checked={isCheckAll}
                            onChange={(e) => onCheckAll(e.target.checked, title)}
                        />
                        <label className={'font-sans'} htmlFor={`${pageName}-checkAll`}>
                            <FormattedMessage id={'mailjet.checkAll'} />
                        </label>
                    </div>
                </div>
            )}

            <div className={'flex flex-wrap'}>
                {items.map((item) => (
                    <div className={'checkbox-radio--wrapper w-1/3 mb-3'} key={item.name}>
                        <input
                            type={'checkbox'}
                            id={`${pageName}-${item.name}`}
                            name={item.name}
                            checked={item.checked}
                            onChange={(e) => onChange(item, title)}
                        />
                        <label
                            className={'text-sm font-sans'}
                            htmlFor={`${pageName}-${item.name}`}
                        >
                            <span>{item.label}</span>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    )
}

TopicNewsletter.propTypes = {
    title: PropTypes.any,
    items: PropTypes.any,
    checkAllTopicValue: PropTypes.any,
    requireTopicValue: PropTypes.any,
    onChange: PropTypes.any,
    isRequireTopicChecked: PropTypes.any,
    pageName: PropTypes.any,
    onCheckAll: PropTypes.any,
    invalidTopic: PropTypes.any,
    isCheckAll: PropTypes.any,
}

export default TopicNewsletter
