import _ from 'lodash'
import fetch from '@frontastic/bridge/js/fetch';

class SubscribeService {
    async getSubscribeInfo() {
        try {
            const res = await fetch('/api/subscribe/newsletter/topics', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return await res.json()
        } catch (e) {
            throw e
        }
    }

    async subscribe(data) {
        try {
            const res = await fetch('/api/subscribe/newsletter/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            return await res.json()
        } catch (e) {
            throw e
        }
    }

    updateTopics(topics, name, title, requireTopicValue) {
        if (!topics || !title) {
            return
        }

        const newTopics = { ...topics }
        const topic = newTopics[title]
        const indexByName = topic.findIndex((item) => item.name === name)

        if (indexByName !== -1) {
            if (requireTopicValue.includes(title)) {
                if (title.toLowerCase().trim() === 'news') {
                    topic.forEach((item, index) => {
                        if (index !== indexByName && item.checked) {
                            item.checked = false
                        }
                    })
                }
                topic[indexByName].checked = !topic[indexByName].checked
            }
        }

        newTopics[title] = topic

        return newTopics
    }

    updateAllTopicValueByChild(newTopics, isCheckAll, title) {
        if (!title || !newTopics) {
            return
        }

        let status = true

        if ((title.toLowerCase().trim() !== 'sport') || !newTopics) {
            status = isCheckAll
            return status
        }

        const topic = newTopics[title]
        return !(topic.some((item) => !item.checked))
    }

    validateRequireTopics(topics, requireTopicValue) {
        if (!topics) {
            []
        }

        let status = true
        const invalidTopicArr = []

        requireTopicValue.map((topic) => {
            const topicItems = topics[topic]
            const indexTopicItem = topicItems.findIndex((item) => item.checked)

            if (indexTopicItem === -1) {
                status = false

                if (!invalidTopicArr.includes(topic)) {
                    invalidTopicArr.push(topic)
                }
            }
        })

        return invalidTopicArr
    }

    collectTopicValue(topics) {
        const result = {}
        Object.keys(topics).forEach((key) => {
            topics[key].forEach((topic) => {
                result[topic.name] = !!topic.checked
            })
        })

        return result
    }

    checkAllTopicValue(isCheckAll, title, topics) {
        if (!topics || !title) {
            return
        }

        const newTopics = { ...topics }
        const topic = newTopics[title]

        if (title.toLowerCase().trim() === 'sport') {
            topic.forEach((value) => {
                value.checked = !isCheckAll
            })
        }

        newTopics[title] = topic

        return newTopics
    }

    initTopics(originalTopics, checkAllTopicValue) {
        if (_.isEmpty(originalTopics)) {
            return null
        }

        Object.keys(originalTopics).forEach((key) => {
            if (key === checkAllTopicValue) {
                originalTopics[key].forEach((topic) => {
                    topic.checked = true
                })
            }
        })

        return originalTopics
    }
}

export default new SubscribeService()
