import React, { MouseEventHandler } from 'react'
import classnames from 'classnames'
import Button from './Button'

export type Props = {
    type: 'button' | 'submit' | 'reset' | undefined
    ariaLabel: string
    className?: String
    id?: string
    disabled?: boolean
    children?: React.ReactNode
    onClick?: MouseEventHandler
    darkTheme?: boolean
    fullWidth?: boolean
    icon: React.ReactNode
    style?: React.CSSProperties
}

const IconButton: React.FC<Props> = ({
    type = 'button',
    ariaLabel,
    className,
    disabled = false,
    children,
    onClick = () => {
    },
    darkTheme = false,
    fullWidth = false,
    icon,
    style,
    id,
}: Props) => (
    <Button
        // @ts-ignore
        className={classnames(className, {
            'dark-mode': darkTheme,
            'full-width': fullWidth,
        })}
        type={type}
        ariaLabel={ariaLabel}
        disabled={disabled}
        onClick={onClick}
        style={style}
        id={id}
    >
        {icon && icon}
        {children && children}
    </Button>
)

export default IconButton
