import React from 'react'
import { ErrorMessage as Message } from '@hookform/error-message'

export type Props = {
    errors: any,
    name: string,
    as?: any
}

const ErrorMessage: React.FC<Props> = ({
    errors, name, as = 'div',
}: Props) => {
    if (!errors[name]) {
        return null
    }

    return (
        <Message
            className={'error-message-text-system-error'}
            errors={errors}
            name={name}
            as={as}
        />
    )
}

ErrorMessage.propTypes = {}

export default ErrorMessage
