import React, { useRef, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import app from '@frontastic/catwalk/src/js/app/app'
import { useForm } from 'react-hook-form'
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl'
import _ from 'lodash'
import Modal from 'react-modal'
import BaseDotted from '../../atoms/dotted/BaseDotted'
import { DottedFadebarVariantA5mm, DottedFadebarVariantB5mm } from '../../atoms/dotted'
import ErrorMessage from '../../atoms/errorMessage'
import Button from '../../atoms/button/Button'
import IconButton from '../../atoms/button/IconButton'
import DefaultLoader from '../../molecules/Loaders/DefaultLoader'
import { ReactComponent as CloseIcon } from '../../../../icons/sport2000-icons/close.svg'
import { ReactComponent as CheckCircleIcon } from '../../../../icons/sport2000-icons/check-circle.svg'
import SubscribeService from '../../../services/subscribe'
import NewsletterForm from '../Forms/NewsletterForm'
import fetch from '@frontastic/bridge/js/fetch'

const MailjetNewsletter = ({
    bgcolor,
    isDotted,
    intl,
    isIframe,
    checkAllKey,
    checkRequiredKey,
    headlineText,
    subHeadlineText,
    topics: arrayTopics,
}) => {
    const requiredField = intl.formatMessage({ id: 'validation.required' })
    const ref = useRef()
    const config = {
        mailjetScr: app.getApi().router.context.projectConfiguration.mailJetScr,
    }

    const [topics, setTopics] = useState(arrayTopics.topics)
    const topicRequired = checkRequiredKey.replace(/\s/g, '').split(',')
    const [invalidTopic, setInvalidTopic] = useState('')
    const [isCheckAll, setIsCheckAll] = useState(true)
    const [isRequireTopicChecked, setIsRequireTopicChecked] = useState(true)
    const onUpdateSubscribe = () => {
        SubscribeService.getSubscribeInfo().then((res) => {
            setTopics(SubscribeService.initTopics(res.topics, checkAllKey))
        })
    }

    const [togglePopUp, setTogglePopUp] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [displayContent, setDisplayContent] = useState(false)
    const [checked, setChecked] = useState(false)
    const [isValidRequire, setIsValidRequire] = useState(true)
    const [errorMessage, setErrorMessage] = useState(false)

    const elementMailjet = typeof document !== 'undefined' ? document.getElementById('mailjet--privacy') : null

    const {
        register,
        handleSubmit,
        getValues,
        setError,

        formState: {
            errors,
        },
    } = useForm()

    const style = {
        backgroundColor: bgcolor,
    }

    const onChangeTopics = (item, title) => {
        if (topicRequired.indexOf(title) > -1) {
            setIsRequireTopicChecked(true)
        }

        const newTopics = SubscribeService.updateTopics(topics, item.name, title, topicRequired)
        const shouldUpdateCheckAll = SubscribeService.updateAllTopicValueByChild(newTopics, isCheckAll, title)
        setTopics(newTopics)
        setIsCheckAll(shouldUpdateCheckAll)
    }

    const onCheckAllTopic = (isChecked, title) => {
        setTopics(SubscribeService.checkAllTopicValue(isCheckAll, title, topics))
        setIsCheckAll(!isCheckAll)
    }

    const checkValidEmail = (value) => {
        if (_.isEmpty(value)) {
            return false
        }

        const isValid = value.toString().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

        return !_.isEmpty(isValid)
    }

    const renderDottedBar = () => {
        if (!isDotted) {
            return null
        }

        const dottedColor = bgcolor

        return (
            <div className={'teaser--dotted-bar'}>
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantA5mm fill={dottedColor} />}
                    height={14}
                    isTop
                />
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantA5mm fill={dottedColor} />}
                    height={14}
                    isBottom
                    isRotate
                    from={'right'}
                />
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantB5mm fill={dottedColor} />}
                    height={14}
                    isBottom
                    isRotate
                />
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantB5mm fill={dottedColor} />}
                    height={14}
                    isTop
                    from={'right'}
                />
            </div>
        )
    }

    const checkPrivacyFunc = () => {
        if (!elementMailjet) {
            return null
        }

        if (!checked) {
            return elementMailjet.classList.remove('is--invalid')
        }

        return elementMailjet.classList.add('is--invalid')
    }

    const toggleMailjetPopup = () => {
        const formData = getValues()
        const { email } = formData

        if (!checkValidEmail(email)) {
            setTogglePopUp(false)
            setError('email', { type: 'required', message: intl.formatMessage({ id: 'validation.email' }) })

            return
        }

        setTogglePopUp(true)
        onUpdateSubscribe()
    }

    // format data before post
    const collectData = () => {
        const formData = getValues()
        const { email } = formData

        const newData = {
            contactId: arrayTopics.contactId,
            email,
            topics: SubscribeService.collectTopicValue(topics),
        }
        return newData
    }

    const checkRequireTopicsBeforeSubmit = (topics, topicRequired) => {
        const resultValidateTopic = SubscribeService.validateRequireTopics(topics, topicRequired)

        if (resultValidateTopic.length === 0) {
            setIsValidRequire(true)
            return true
        }

        if (!elementMailjet) {
            return null
        }

        if (!checked) {
            elementMailjet.classList.add('is--invalid')
        }

        setIsRequireTopicChecked(false)
        setIsValidRequire(false)
        setInvalidTopic(resultValidateTopic)

        return false
    }

    const subscribeEmail = () => {
        if (!checkRequireTopicsBeforeSubmit(topics, topicRequired)) {
            return
        }

        if (!checked) {
            elementMailjet.classList.add('is--invalid')
            return
        }

        const bodyData = collectData()

        if (!bodyData.email.trim() || isLoading === true) {
            return
        }

        setIsRequireTopicChecked(true)
        setIsLoading(true)

        fetch('/api/subscribe/newsletter/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(bodyData),
        }).then((res) => {
            if (!res.ok) {
                setErrorMessage(true)
                setIsLoading(false)
                setDisplayContent(true)
                return
            }
            setTogglePopUp(true)
            setIsLoading(false)
            setDisplayContent(true)
            setTopics(arrayTopics.topics)
            setIsCheckAll(true)
            setChecked(false)
        })
    }

    const PopUpMail = () => (
        <Modal
            isOpen={!!togglePopUp}
            portalClassName={'mailjet-popup-mail'}
            onRequestClose={() => {
                setTogglePopUp(false)
            }}
            contentLabel={'mailjet modal'}
        >
            <div className={'mailjet-popup-wrapper'}>
                <div className={'mailjet-popup'}>
                    <div className={'mailjet-popup-close'}>
                        <IconButton
                            icon={(
                                <CloseIcon
                                    width={15}
                                    height={15}
                                />
                            )}
                            onClick={() => {
                                setTogglePopUp(false)
                                setDisplayContent(false)
                                setIsLoading(false)
                            }}
                        />
                    </div>
                    <>
                        {isLoading && (
                            <div className={'mailjet--loading'}>
                                <DefaultLoader />
                            </div>
                        )}

                        {!displayContent ? (
                            <div className={classnames('mailjet-topics', {
                                hidden: isLoading,
                            })}
                            >
                                <h4 className={'mailjet-popup-headline'}>
                                    <FormattedMessage id={'mailjet.title'} />
                                </h4>
                                <div className={'mailjet-popup-topics'}>
                                    <NewsletterForm
                                        topics={topics}
                                        checkAllTopicValue={checkAllKey}
                                        isRequireTopicChecked={isRequireTopicChecked}
                                        onChange={(item, title) => {
                                            onChangeTopics(item, title)
                                        }}
                                        isCheckAll={isCheckAll}
                                        onCheckAll={(isCheckAll, title) => {
                                            onCheckAllTopic(isCheckAll, title)
                                        }}
                                        invalidTopic={invalidTopic}
                                    />
                                </div>
                                <div className={'mailjet-popup-topics-privacy mt-4 pt-4'}>
                                    <div
                                        className={'checkbox-radio--wrapper w-full'}
                                        id={'mailjet--privacy'}
                                    >
                                        <input
                                            key={'checkPrivacy'}
                                            type={'checkbox'}
                                            id={'checkPrivacy'}
                                            {...register('checkPrivacy', { required: requiredField })}
                                            // checked={!checked}
                                            onChange={() => {
                                                setChecked(!checked)
                                                checkPrivacyFunc()
                                            }} />
                                        <label className={'font-sans'} htmlFor={'checkPrivacy'}>
                                            <FormattedHTMLMessage id={'mailjet.checkPrivacy'} />
                                        </label>
                                    </div>
                                </div>
                                <div className={'mailjet-popup-action'}>
                                    <div className={'mailjet-popup-action-submit'}>
                                        <Button
                                            type={'button'}
                                            className={'btn btn-default w-full'}
                                            ariaLabel={intl.formatMessage({ id: 'mailjet.submit' })}
                                            onClick={subscribeEmail}
                                        >
                                            <FormattedMessage id={'mailjet.submit'} />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                {errorMessage ? (
                                    <>
                                        <div className={'popup-mailjet-confirm-content'}>
                                            <h4 className={'popup-mailjet-confirm-content-headline'}>
                                                <FormattedMessage id={'mailjet.errorHeadline'} />
                                            </h4>
                                            <div className={'popup-mailjet-confirm-content-description'}>
                                                <FormattedMessage id={'mailjet.errorDescription'} />
                                            </div>
                                            <div className={'popup-mailjet-confirm-content-button'}>
                                                <Button
                                                    type={'button'}
                                                    className={'btn btn-secondary'}
                                                    ariaLabel={intl.formatMessage({ id: 'mailjet.cancel' })}
                                                    onClick={() => {
                                                        setTogglePopUp(false)
                                                        setDisplayContent(false)
                                                        setIsLoading(false)
                                                    }}
                                                >
                                                    <FormattedMessage id={'mailjet.confirmButton'} />
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={'popup-mailjet-confirm-icon'}>
                                            <CheckCircleIcon width={28} height={28} />
                                        </div>
                                        <div className={'popup-mailjet-confirm-content'}>
                                            <h4 className={'popup-mailjet-confirm-content-headline'}>
                                                <FormattedMessage id={'mailjet.confirmHeadline'} />
                                            </h4>
                                            <div className={'popup-mailjet-confirm-content-description'}>
                                                <FormattedMessage id={'mailjet.confirmDescription'} />
                                            </div>
                                            <div className={'popup-mailjet-confirm-content-button'}>
                                                <Button
                                                    type={'button'}
                                                    className={'btn btn-secondary'}
                                                    ariaLabel={intl.formatMessage({ id: 'mailjet.cancel' })}
                                                    onClick={() => {
                                                        setTogglePopUp(false)
                                                        setDisplayContent(false)
                                                        setIsLoading(false)
                                                    }}
                                                >
                                                    <FormattedMessage id={'mailjet.confirmButton'} />
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </>
                </div>
            </div>
        </Modal>
    )

    const onKeyPressHandle = (event) => {
        if (event.keyCode === 13) {
            toggleMailjetPopup()
        }
    }

    return (
        <div
            className={classnames('relative', {
                ' mailjet-newsletter': bgcolor,
                'my-14px': isDotted,
            })}
            style={style}
        >
            {renderDottedBar()}
            <>
                {isIframe ? (
                    <div className={'o-wrapper'}>
                        <iframe
                            title={'Newsletter Formular'}
                            className={'mj-w-res-iframe'}
                            frameBorder={'0'}
                            scrolling={'no'}
                            marginHeight={'0'}
                            marginWidth={'0'}
                            src={config.mailjetScr}
                            width={'100%'}
                            height={'310'}
                            referrerPolicy={'origin-when-cross-origin'}
                        />
                    </div>
                ) : (
                    <>
                        <div className={'o-wrapper'}>
                            <div className={'mailjet-newsletter-wrapper'}>
                                <div className={'mailjet-newsletter-wrapper-content'}>
                                    <h2 className={'mailjet-newsletter-wrapper-content-headline'}>
                                        {headlineText}
                                    </h2>
                                    <p className={'mailjet-newsletter-wrapper-content-description'}>
                                        {subHeadlineText}
                                    </p>
                                </div>
                                <div className={'mailjet-newsletter-wrapper-form'}>
                                    <form onSubmit={handleSubmit(subscribeEmail)}>
                                        <div className={'mailjet-newsletter--form'}>
                                            <div
                                                className={classnames('mb-4 input--wrapper mailjet-newsletter--form-input lg:mb-0', {
                                                    'is--invalid': errors?.email,
                                                })}
                                            >
                                                <input
                                                    id={'mailjet-email'}
                                                    {...register('email', {
                                                        required: requiredField,
                                                    })}
                                                    type={'email'}
                                                    placeholder={intl.formatMessage({ id: 'account.form.emailLabel' })}
                                                    onKeyUp={onKeyPressHandle} />
                                                <label htmlFor={'mailjet-email'}>
                                                    <FormattedMessage id={'account.form.emailLabel'} />
                                                </label>
                                                <span className={'input--border'} />
                                                {errors?.email
                                                    && (
                                                        <span className={'input--hint'}>
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name={'email'}
                                                            />
                                                        </span>
                                                    )}
                                            </div>
                                            <div className={'mailjet-newsletter--form-button'}>
                                                <Button
                                                    type={'button'}
                                                    ariaLabel={intl.formatMessage({ id: 'mailjet.subscribeButton' })}
                                                    className={'btn btn-secondary dark-mode w-full lg:w-auto'}
                                                    onClick={toggleMailjetPopup}
                                                >
                                                    <FormattedMessage id={'mailjet.subscribeButton'} />
                                                </Button>
                                            </div>
                                        </div>
                                        {togglePopUp && (
                                            <div className={'mailjet--popup'} ref={ref}>
                                                {PopUpMail()}
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        </div>
    )
}

MailjetNewsletter.propTypes = {
    intl: PropTypes.object.isRequired,
    bgcolor: PropTypes.string,
    checkAllKey: PropTypes.string,
    topics: PropTypes.object.isRequired,
    isDotted: PropTypes.bool,
    isIframe: PropTypes.bool,
    checkRequiredKey: PropTypes.string,
    headlineText: PropTypes.string,
    subHeadlineText: PropTypes.string,
}

export default injectIntl(MailjetNewsletter)
