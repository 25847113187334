import React, {useState} from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import MailjetNewsletter from '../../../patterns/organisms/MailjetNewsletter'
import SubscribeService from "../../../services/subscribe";

const NewsletterBlock = ({ data }) => {
    const {
        bgcolor, isDotted, isIframe, checkAllKey, checkRequiredKey, subHeadlineText, headlineText, topics
    } = data

    return (
        <MailjetNewsletter
            bgcolor={bgcolor}
            isDotted={isDotted}
            isIframe={isIframe}
            checkAllKey={checkAllKey}
            checkRequiredKey={checkRequiredKey}
            headlineText={headlineText}
            subHeadlineText={subHeadlineText}
            topics={topics}
        />
    )
}

NewsletterBlock.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify()(NewsletterBlock)
