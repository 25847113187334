import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import TopicNewsletter from '../TopicNewsletter'

const NewsletterForm = ({
    topics,
    onChange,
    requireTopicValue,
    checkAllTopicValue,
    isRequireTopicChecked,
    pageName,
    onCheckAll,
    invalidTopic,
    isCheckAll,
}) => {
    if (!topics) {
        return null
    }

    return (
        <>
            <div className={'flex justify-between newsletter-contact'}>
                <div className={'font-bold text-2xl mb-2 newsletter-contact-headline'}>
                    <FormattedMessage id={'newsletterContact.headline'} />
                </div>
                <div className={'font-bold font-sport2000 text-sm my-auto text-blacksport-50'}>
                    <FormattedMessage id={'validation.fieldRequired'} />
                </div>
            </div>

            {Object.keys(topics).map((key) => (
                <TopicNewsletter
                    key={key}
                    title={key}
                    items={topics[key]}
                    requireTopicValue={requireTopicValue}
                    checkAllTopicValue={checkAllTopicValue}
                    isRequireTopicChecked={isRequireTopicChecked}
                    pageName={pageName}
                    onChange={onChange}
                    onCheckAll={onCheckAll}
                    invalidTopic={invalidTopic}
                    isCheckAll={isCheckAll}
                />
            ))}
        </>

    )
}

NewsletterForm.propTypes = {
    topics: PropTypes.any,
    requireTopicValue: PropTypes.any,
    checkAllTopicValue: PropTypes.any,
    onChange: PropTypes.any,
    isRequireTopicChecked: PropTypes.any,
    pageName: PropTypes.any,
    onCheckAll: PropTypes.any,
    invalidTopic: PropTypes.any,
    isCheckAll: PropTypes.any,
}

export default NewsletterForm
